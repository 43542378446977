<template>
  <v-app>
    <body>
      <v-container>
        <v-container class="white-bg mt-32px">
          <div class="text-xs-center section">
              <div class="ct-mw navy-text">
                <v-img
                  :src="require('@/assets/image/achieve-plus-favicon.svg')"
                  contain
                  height="80"
                />
                <br/>
                <v-row justify="center">
                  <span class="subtitle">
                    <strong>การดำเนินการชำระเงินผิดพลาด!</strong>
                  </span><br/>
                </v-row>
                <v-row justify="center">
                  โปรดติดต่อทีมงาน Achieve Plus
                <br/><br/>
                </v-row>
                <v-row justify="center">
                   สอบถามเพิ่มเติม
                   <a class="ml-2" href="mailto:billing@achieve.plus">
                     billing@achieve.plus</a><br/>
                </v-row>
              </div>
              <br/>
              <v-row justify="center">
                <router-link to="/profile/index">
                  <v-btn
                  color="error">OK</v-btn>
                </router-link>
              </v-row>
          </div>
        </v-container>
      </v-container>
    </body>
  </v-app>
</template>

<script>
export default {

};
</script>


<style lang="sass" scoped>
.ct-mw
  max-width: 960px
  margin: auto
.white-bg
  max-width: 1100px
  margin: auto
.section
  padding: 16px
  border-color: #DBDBDB
  border-radius: 5px
  border-style: solid
  margin: auto

.detail-text
  font-weight: 200

.bg-grey
  background-color: #DBDBDB
  padding-top: 4px
  padding-bottom: 4px

.mw-divider
  max-width: 300px
  margin-left: auto
  margin-right: auto
  margin-top: -8px

.mw-method
  max-width: 200px
  margin-top: 16px
  margin-bottom: 16px
.mt-32px
  margin-top: 32px
</style>
